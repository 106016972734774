import React, { useState, useEffect } from "react";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import "./Footer.css";
import logoImage from "../../Assets/images/terz_logo.svg";
import { useForm } from "@formspree/react";

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleScrollToSection = (sectionId, e) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const [state, handleSubmit] = useForm("mayrzdqq");

  useEffect(() => {
    if (state.succeeded) {
      setName("");
      setEmail("");
      setMessage("");
    }
  }, [state.succeeded]);

  return (
    <footer className="footer">
      {/* Logo and Address Section */}
      <div className="footer-section logo-address">
        <img src={logoImage} alt="Company Logo" className="company-logo" />
        <p className="company-address">
          Times Square Building, Office 4, 5 & 6, Tower E, 5th Floor, Marol, Andheri Kurla Road, Mumbai 400059, Maharashtra, INDIA.
          <br />
          Phone: +9122-40751000
          <br />
          Email: cc@tarzlifestyle.com
        </p>
      </div>

      {/* About Us Section */}
      <div className="footer-section about">
        <h2>About Us</h2>
        <p>TARZ Distribution India Pvt. Ltd. is a leading partner for luxury
          beauty in India, with a history of excellence in distribution and
          marketing services.</p>
      </div>

      {/* Follow Us Section */}

      {/* Quick Links Section */}
      <div className="footer-section links">
        <h2>Quick Links</h2>
        <ul>
          <li onClick={(e) => handleScrollToSection("about-us", e)}>
            ABOUT US
          </li>
          <li onClick={(e) => handleScrollToSection("OurPortfolio", e)}>
            OUR PORTFOLIO
          </li>
          <li onClick={(e) => handleScrollToSection("Retailers", e)}>
            RETAILERS
          </li>
          <li onClick={(e) => handleScrollToSection("why-choose-us", e)}>
            WHY CHOOSE US
          </li>
          <li onClick={(e) => handleScrollToSection("ebos", e)}>BOUTIQUES</li>
          <li onClick={(e) => handleScrollToSection("events", e)}>EVENTS</li>
          <li onClick={(e) => handleScrollToSection("our-impact", e)}>
            OUR IMPACT
          </li>
        </ul>
      </div>

      {/* Contact Us Section */}
      <div className="footer-section contact-us">
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit} className="contact-form">
          <input
            id="Name"
            name="Name"
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            id="email"
            name="email"
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            id="message"
            name="message"
            placeholder="Your Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button type="submit">Send</button>
        </form>
        {state.succeeded && <p>Thanks for joining!</p>}
      </div>

      <div className="footer-bottom">
        <div className="follow-us">
          <h2>Follow Us</h2>
          <div className="social-icons">
            <a
              href="https://www.instagram.com/tarzbeauty"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/tarz-distribution-india-private-limited"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn />
            </a>
          </div>
        </div>
        &copy; {new Date().getFullYear()} TARZ Distribution India Pvt. Ltd. |
        All Rights Reserved
        <p className="created-by">Created by Manav Mabian</p>
      </div>
    </footer>
  );
};

export default Footer;
